import config from '../config';
import { APPLE_PAY_MERCHANT_IDENTIFIER } from '../env/public';

const SDK_URL = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';
/** For testing cross-browser support in the future. */
const SDK_URL_BETA =
  'https://applepay.cdn-apple.com/jsapi/v1.2.0-beta/apple-pay-sdk.js';

/** General AWS configuration. */
const applePay = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Whether Apple Pay features are globally enabled.
       *
       * Setting this to `false` should prevent the Apple Pay button from being displayed,
       * and the `ApplePayService` from being used.
       */
      enabled: {
        default: true,
        prod: false
      },

      /**
       * The URL of the Apple Pay SDK.
       */
      sdkURL: SDK_URL,

      /**
       * The version of Apple Pay supported by the site.
       * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history Apple Pay on the web version history}
       * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778014-supportsversion ApplePaySession.supportsVersion()}
       */
      version: 3,

      /**
       * The merchant ID. For payment platforms registering merchants through the
       * {@link https://developer.apple.com/documentation/applepaywebmerchantregistrationapiApple Pay Web Merchant Registration API}, this should be the `partnerInternalMerchantIdentifier`
       * defined for the each registered merchant.
       */
      merchantIdentifier: {
        default:
          APPLE_PAY_MERCHANT_IDENTIFIER ?? 'merchant.com.applepay.deckers.na',
        prod:
          APPLE_PAY_MERCHANT_IDENTIFIER ??
          'merchant.com.applepay.deckers.prod.na'
      },

      /**
       * The static URL of the Apple Pay server for validating the merchant when initializing an Apple Pay session.
       * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/requesting_an_apple_pay_payment_session Requesting an Apple Pay payment session}
       * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778021-onvalidatemerchant onvalidatemerchant}
       */
      merchantValidationURL: {
        default:
          'https://apple-pay-gateway-cert.apple.com/paymentservices/paymentSession', // for sandbox environments only
        prod: 'https://apple-pay-gateway.apple.com/paymentservices/paymentSession'
      },

      /**
       * A string of 64 or fewer UTF-8 characters containing the canonical name for your store,
       * suitable for display. This needs to remain a consistent value for the store and shouldn’t
       * contain dynamic values such as incrementing order numbers. Don’t localize the name. Use
       * only characters from supported character sets.
       */
      displayName: 'Deckers Brands',

      /**
       * The supported payment networks.
       *
       * For a list recognized payment networks and their first available version:
       * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks ApplePayPaymentRequest.supportedNetworks}
       */
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover']
    }
  },
  /** Site-specific settings. */
  'AHNU-US': {
    default: {
      enabled: {
        default: true,
        prod: false
      },
      displayName: 'Ahnu'
    }
  }
});

export default applePay;
