import config from '../config';
import { TALONONE_API_KEY } from '../env/server';

/** Configuration for the Talon.One integration. */
const talonOne = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Base URL of the Talon.One instance to use. */
      baseURL: 'https://deckers.us-east4.talon.one/',

      /**
       * API Key to authenticate requests with.
       * @see https://docs.talon.one/integration-api#section/Authentication/api_key_v1
       */
      apiKey: TALONONE_API_KEY as string,

      /**
       * The API name of the _additional cost_ that will contain shipping
       * charges.
       *
       * @see https://docs.talon.one/docs/product/account/dev-tools/managing-additional-costs
       *
       * In our Talon integration, shipping charges applied to an order are sent
       * as an additional cost with this name. This way we can easily apply
       * effects to shipping costs only.
       */
      shippingAdditionalCostAPIName: 'shippingCost',

      /**
       * The API name of the _custom attribute_ that will contain the code of
       * the shipping method being used for a customer session.
       *
       * @see https://docs.talon.one/docs/dev/concepts/attributes#custom-attributes
       *
       * For our use case, some promotion rules have to factor in the shipping
       * method in use. That's why we configured this custom attribute, in which
       * we send the code of the shipping method selected (e.g. `STG`) for a
       * given customer session.
       */
      shippingCodeAttributeAPIName: 'shippingMethodCode'
    }
  }
});

export default talonOne;
