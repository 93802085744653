import config from '../config';

import { PAYPAL_CLIENT_ID } from '../env/public';
import {
  PAYPAL_CLIENT_SECRET,
  PAYPAL_NVP_PASSWORD,
  PAYPAL_NVP_SIGNATURE,
  PAYPAL_NVP_USERNAME
} from '../env/server';

const paypal = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * If PayPal should be enabled as a payment method.
       *
       * This setting should be considered as a "master switch", since disabling
       * it would disable PayPal as an express checkout option as well.
       */
      enabled: {
        default: true,
        prod: true
      },

      /**
       * If PayPal should be enabled as an express checkout option.
       *
       * The `enabled` above must also be `true` in order for PayPal to show up
       * in the express checkout options.
       */
      enableExpressCheckout: {
        default: true,
        prod: true
      },

      /**
       * Identifies the PayPal account that sets up and finalizes
       * transactions.
       */
      clientID: PAYPAL_CLIENT_ID,

      /** Authenticates the client ID. */
      clientSecret: PAYPAL_CLIENT_SECRET,

      /**
       * Settings for the PayPal NVP API Integration.
       * @see https://developer.paypal.com/api/nvp-soap/nvp/
       *
       * For context, the NVP API is an older API, and only one of multiple
       * supported APIs for integrating with PayPal.
       *
       * Do note that currently, PayPal recommends using the
       * [Orders V2 API](https://developer.paypal.com/docs/api/orders/v2/)
       * instead; which has its own settings below.
       */
      nvp: {
        baseURL: {
          default: 'https://api-3t.sandbox.paypal.com/nvp',
          prod: 'https://api-3t.paypal.com/nvp'
        },

        /**
         * Authentication credentials. We are using the _API Signature_ authentication
         * method.
         *
         * @see https://developer.paypal.com/api/nvp-soap/apiCredentials/#api-signatures
         */
        username: PAYPAL_NVP_USERNAME,
        password: PAYPAL_NVP_PASSWORD,
        signature: PAYPAL_NVP_SIGNATURE,

        version: '63.0'
      },

      /**
       * Settings for the PayPal Orders V2 API Integration.
       * @see https://developer.paypal.com/docs/api/orders/v2/
       */
      ordersV2: {
        baseURL: 'https://api-m.sandbox.paypal.com'
      }
    }
  }
});

export default paypal;
