import config from './config';

const menu = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Sanuk Menu Config. */

      /**
       * The main menu settings. Note that by default, we pull the main menu
       * from the CMS. If you want to override this, set overrideCmsValues to
       * true and add your own links.
       */
      mainMenu: {
        showUtilityLinks: true,
        showWishlistLink: true,
        overrideCmsValues: false,
        links: [{ id: '', label: '', href: '' }]
      },
      mobileMenu: { showUtilityLinks: true }
    }
  },
  'AHNU-US': {
    default: {
      mainMenu: {
        showUtilityLinks: false,
        showWishlistLink: false,
        overrideCmsValues: true,
        links: [
          {
            id: 'suede',
            label: 'Sequence 1.1 Suede',
            href: '/p/Sequence-Suede?style=1171318&color=OAT'
          },
          {
            id: 'mens',
            label: 'Sequence 1',
            href: '/p/Sequence?style=1166650'
          },
          { id: 'sport', label: 'Sequence 1.1 Sport', href: '/p/1171071-WWH' },
          { id: 'about', label: 'About AHNU', href: '/about' }
        ]
      },
      mobileMenu: { showUtilityLinks: false }
    }
  },
  'UGG-COLLAB-US': {
    default: {
      mainMenu: {
        overrideCmsValues: true,
        links: [{ id: '1170430', label: 'All', href: '/p/1170430' }]
      }
    }
  },
  'TEVA-US': {
    default: {
      mainMenu: {
        overrideCmsValues: true,
        links: [
          { id: 'women', label: 'Women', href: '/p/1090969' },
          { id: 'men', label: 'Men', href: '/p/1090969' },
          { id: 'kids', label: 'Kids', href: '/p/1090969' },
          { id: 'inside-teva', label: 'Inside Teva', href: '/about' }
        ]
      }
    }
  },
  'HOKA-US': {
    default: {
      mainMenu: {
        overrideCmsValues: true,
        links: [{ id: '1155120', label: 'All', href: '/p/1155120' }]
      }
    }
  }
});

export default menu;
