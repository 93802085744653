import config from './config';

const checkout = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * This value controls “Use Shipping as Billing Address” checkbox to be
       * checked by default in checkout page.
       */
      useShippingAsBillingAddressAsDefault: true,
      /**
       * This value control whether there is a “order review” step in checkout
       * or if, after billing, the next step is order submission.
       */
      isCheckoutWithReviewStep: false,

      /** If shipping to US territories should be allowed. */
      allowShippingToUSTerritories: false,

      /** If shipping orders to PO Boxes should be allowed or not. */
      allowShippingToPOBoxes: false,

      /**
       * If this is set to false then international shipping is disabled from checkout.
       * The user will be unable to select a country outside of the current locale.
       */
      allowInternationalShipping: false,

      /**
       * Whether or not to display the 'Log in' message / link above shipping form fields.
       */
      showLoginMessage: true,

      /**
       * Payment methods to show in checkout.
       */
      paymentMethod: {
        /**
         * If this is set to false then the CREDIT CARD payment method will be disabled from checkout.
         */
        creditCard: true,

        /**
         * If this is set to false then the PAYPAL payment method will be disabled from checkout.
         */
        paypal: {
          default: true,
          prod: false
        },

        /**
         * If this is set to false then the APPLE PAY payment method will be disabled from checkout.
         */
        applePay: {
          default: true,
          prod: false
        },

        /**
         * If this is set to false then the GOOGLE PAY payment method will be disabled from checkout.
         */
        googlePay: false,

        /**
         * If this is set to false then the KLARNA payment method will be disabled from checkout.
         */
        klarna: false
      },
      /**
       * If this is set to true then the country information will be hidden from Shipping Summary in checkout page.
       */
      hideCountry: false
    }
  },
  'AHNU-US': {
    default: {
      paymentMethod: {
        paypal: {
          default: true,
          prod: true
        },
        applePay: {
          default: true,
          qa: true,
          prod: false
        },
        googlePay: false,
        klarna: false
      },
      hideCountry: true
    }
  }
});

export default checkout;
