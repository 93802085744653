import type { ConfigName, Configs } from '@/configs';
import MemoryCache, { GetMethod } from '@/utils/MemoryCache';

import { EnvironmentError } from '@/utils/errors';
import { isNullish } from '@/utils/null-utils';
import { EnvironmentService } from '../EnvironmentService';
import I18NService, { Country, Language } from '../I18NService';
import Config from './Config';

/**
 * `ConfigurationService` is used to obtain various site configurations.
 *
 * **Note:** This service uniquely does not extend {@link Service} because
 * it is actually used in `Service` to determine whether a service should
 * be mocked.
 */
class ConfigurationService {
  /** A cache in which to store the config instances. */
  private configCache = new MemoryCache<Config<keyof Configs>>();

  /**
   * Retrieves a {@link Config} for the given `configName` corresponding to config file in the
   * "src/configs/" directory.
   * @param configName - The name of the config which this object represents. Valid
   * configs can be found in the "src/configs/" directory.
   * @param [language] - The language specific for these config values. This is determined
   * heuristically, by default.
   * @param [country] - The country specific for these config values. This is determined
   * heuristically, by default.
   * @returns The {@link Config} object representing the given configuration key.
   * @throws {@link EnvironmentError} If the locale could not be determined.
   */
  public getConfig<T extends ConfigName>(
    configName: T,
    language?: Language,
    country?: Country
  ): Config<T> {
      const site = (process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase());
    if (isNullish(language) || isNullish(country)) {
      try {
        const locale = I18NService.currentLocale;
        language ??= locale.language;
        country ??= locale.country;
      } catch (error) {
        throw new EnvironmentError(
          `Error occurred while determining locale for config "${configName}".`,
          { cause: error }
        );
      }
    }

    /**
     * Ensure we are not caching one brand and country's config for all.
     *
     * **Note**: Even though we typically expect a user to only access the site from
     * one country, Next.js by default performs navigations between locales using
     * SPA-style navigations, rather than a full reload. As a result, the `configCache`
     * remains in memory, and so we can't optimize the key on the client.
     *
     * On the other hand, using these values in the key may enable us in the future
     * to switch brands and locales dynamically during development, without rebuilding.
     */
    const cacheKey = `${site}-${language}-${country}-${configName}`;

    // Get the config from the cache or create a new config
    return this.configCache.get(
      cacheKey,
      () => new Config(configName, language, country),
      GetMethod.Sync
    ) as Config<T>;
  }
}

export default new ConfigurationService();
